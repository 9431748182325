exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-accounting-js": () => import("./../../../src/pages/accounting.js" /* webpackChunkName: "component---src-pages-accounting-js" */),
  "component---src-pages-bookkeeping-js": () => import("./../../../src/pages/bookkeeping.js" /* webpackChunkName: "component---src-pages-bookkeeping-js" */),
  "component---src-pages-business-boton-js": () => import("./../../../src/pages/business-boton.js" /* webpackChunkName: "component---src-pages-business-boton-js" */),
  "component---src-pages-business-structures-js": () => import("./../../../src/pages/business-structures.js" /* webpackChunkName: "component---src-pages-business-structures-js" */),
  "component---src-pages-departing-js": () => import("./../../../src/pages/departing.js" /* webpackChunkName: "component---src-pages-departing-js" */),
  "component---src-pages-income-tax-js": () => import("./../../../src/pages/income-tax.js" /* webpackChunkName: "component---src-pages-income-tax-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-individual-tax-return-js": () => import("./../../../src/pages/individual-tax-return.js" /* webpackChunkName: "component---src-pages-individual-tax-return-js" */),
  "component---src-pages-new-client-js": () => import("./../../../src/pages/new-client.js" /* webpackChunkName: "component---src-pages-new-client-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-rental-schedule-js": () => import("./../../../src/pages/rental-schedule.js" /* webpackChunkName: "component---src-pages-rental-schedule-js" */),
  "component---src-pages-request-callback-js": () => import("./../../../src/pages/request-callback.js" /* webpackChunkName: "component---src-pages-request-callback-js" */),
  "component---src-pages-tax-planning-js": () => import("./../../../src/pages/tax-planning.js" /* webpackChunkName: "component---src-pages-tax-planning-js" */),
  "component---src-pages-taxion-js": () => import("./../../../src/pages/taxion.js" /* webpackChunkName: "component---src-pages-taxion-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

